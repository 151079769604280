import React, { useEffect, useState } from "react";
import PurpleRing from "../../assests/images/purple-ring-form.svg";
import rightArrow from "../../assests/images/btn-right-arrow.png";
import lockImg from "../../assests/images/form-lock.png";
import checkImg from "../../assests/images/greenCheck.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import renderStarImages from "../../hooks/RenderStar";
import { FormRevData } from "../../utils/FormData";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import arrowImg from "../../assests/images/progressbar-arrow.png";
import rightImg from "../../assests/images/progressbar-right.png";
import { parsePhoneNumberFromString } from "libphonenumber-js";

const Que1 = () => {
  // hardcoding the responses given by cli
  const date = new Date();
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // months are zero-indexed
  const year = date.getFullYear();

  const formattedDate = `${day}-${month}-${year}`;
  const [hardCodeResponse, setHardCodeResponse] = useState();
  const userSelectedBtn = localStorage.getItem("selectedButtonText");
  useEffect(() => {
    if (userSelectedBtn === "Accident at work") {
      setHardCodeResponse("SCOT EL");
    } else if (userSelectedBtn === "Accident in a public place") {
      setHardCodeResponse("SCOT PL");
    } else if (userSelectedBtn === "Accident on a private place") {
      setHardCodeResponse("SCOT OL");
    } else if (userSelectedBtn === "Road Traffic Accident") {
      setHardCodeResponse("SCOT RTA");
    } else {
      //
    }
  }, [userSelectedBtn]);
  const [checkBox, setCheckBox] = useState(true);
  const handleCheckBox = () => {
    setCheckBox((prevState) => !prevState);
  };
  const [ipv4Address, setIpv4Address] = useState("");
  const [ipv6Address, setIpv6Address] = useState("");
  useEffect(() => {
    const getIpAddress = async () => {
      try {
        const response = await fetch('/.netlify/functions/getIp');
        const data = await response.json();

       
        setIpv4Address(data.ipv4 || 'Unknown IPv4');
        setIpv6Address(data.ipv6 || 'Unknown IPv6');
      } catch (error) {
      }
    };

    getIpAddress();
  }, []);


  const [formValue, setFormValue] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });
  const [countryCode, setCountryCode] = useState("");

  const FormData = [
    {
      id: 1,
      question: "Did you receive any medical attention?",
      description1:
        "This could have been at the GP, A&E, Walk-in Centre, Physio, Osteo etc. Even ",
      description2: " if you didn't receive any major treatments.",
    },
    {
      id: 2,
      question: "Have you received compensation for this accident already?",
    },
    {
      id: 3,
      question:
        "Good news, you could qualify for compensation. Just 2 more steps to go:",
    },
    {
      id: 4,
      question: `Thanks for that ${formValue?.firstName}, what is your email address?`,
    },
    {
      id: 5,
      question: `Ok ${formValue?.firstName}, please enter your (valid) contact number below so we can get in contact:`,
    },
  ];

  const defaultStyle = {
    background: "linear-gradient(270deg, #08A972 0%, #1B75BB 100%)",
    color: "white",
  };
  const navigate = useNavigate();
  const numbers = [2, 3, 4, 5, 6];
  const data = FormData;
  const revData = FormRevData;
  const [clicked, setClicked] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [responses, setResponses] = useState({});
  const {
    question,
    description1,
    description2,
    id: dataID,
  } = data[currentIndex];
  const { id: revID, userImage, desc, userName } = revData[currentIndex] || {};

  const mergedObject = [{ ...formValue, ...responses }];
  const hasNumbers = /\d/;
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [phoneNumberFlag, setPhoneNumberFlag] = useState("US");

  const handleClick = (value) => {
    setResponses((prev) => ({
      ...prev,
      [data[currentIndex]?.id]: value,
    }));
  };
  const getButtonStyle = (value) => ({
    backgroundColor: clicked === value ? "#02BA76" : "",
    border: clicked === value ? "none" : "",
    color: clicked === value ? "white" : "",
  });
  const loadIntlTelInput = async () => {
    const phoneInput = document.querySelector("#phone-input");
    if (!phoneInput) return;

    const iti = window.intlTelInput(phoneInput, {
      initialCountry: "gb",
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.12/js/utils.js",
    });

    // Set initial country code right after initializing the input
    const selectedCountry = iti.getSelectedCountryData();
    setPhoneNumberFlag(selectedCountry?.iso2.toUpperCase());
    setCountryCode(selectedCountry?.dialCode);

    phoneInput.addEventListener("countrychange", function () {
      const selectedCountry = iti.getSelectedCountryData();
      setPhoneNumberFlag(selectedCountry?.iso2.toUpperCase());
      setCountryCode(selectedCountry?.dialCode);
    });
  };

  useEffect(() => {
    if (currentIndex === 4) {
      loadIntlTelInput();
    }
  }, [currentIndex]);

  const questionOne = "How were you injured?";
  const questionThree = "Did you receive any medical attention?";
  const questionFour =
    "Have you received compensation for this accident already?";
  const handleSubmit = async () => {
    if(!checkBox === true) {
      try {
        const localData = localStorage?.getItem("FormValues");
        const [dataObject] = JSON.parse(localData);
  
        const {
          firstName,
          lastName,
          email,
          phone,
          1: response1,
          2: response2,
        } = dataObject;
  
        let utmParams = {};
        const storedUTMParams = localStorage.getItem("utmParams");
  
        if (storedUTMParams) {
          const utmParamsArray = JSON.parse(storedUTMParams);
          utmParamsArray.forEach((param) => {
            const key = Object.keys(param)[0];
            utmParams[key] = param[key];
          });
        }
        const jsonData = {
          [questionOne]: hardCodeResponse,
          [questionThree]: response1,
          [questionFour]: response2,
          firstName: firstName,
          lastName: lastName,
          email: email,
          phone: `+${countryCode}${phone}`,
          dateSubmittedOn: formattedDate,
          ipv4: ipv4Address,
          ipv6: ipv6Address,
          acceptedPrivacyPolicy: !checkBox ? "yes" : "no",
          ...utmParams,
        };
        fetch("/.netlify/functions/proxyZapier", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(jsonData),
        })
          .then((response) => response.json())
          .then((result) => {
            navigate("/form/success");
          })
          .catch((error) => {
            toast.error(error.message || "An error occurred");
          });
      } catch (error) {
        toast.error(error.message || "An error occurred");
      }
    }
    else {
      toast.warn("Please accept the privacy policy", {toastId: "errorToast"});
    }
  };

  const handleContinue = () => {
    let thirdIndexCondition =
      hasNumbers.test(formValue?.firstName) ||
      hasNumbers.test(formValue?.lastName) ||
      formValue?.firstName === "" ||
      formValue?.lastName === "";
    let fourthIndexCondition =
      formValue?.email === "" || !emailPattern.test(formValue?.email);
    if (currentIndex < data?.length - 1) {
      if (currentIndex === 2) {
        if (thirdIndexCondition) {
          toast.error("Please provide valid input");
        } else {
          setCurrentIndex(currentIndex + 1);
        }
      }

      if (currentIndex === 3) {
        if (fourthIndexCondition) {
          toast.error("Please provide valid email");
        } else {
          setCurrentIndex(currentIndex + 1);
        }
      }
    } else {
      if (currentIndex === 4) {
        const phoneNumber = parsePhoneNumberFromString(
          formValue?.phone,
          phoneNumberFlag
        );
        if (!phoneNumber || !phoneNumber.isValid()) {
          toast.error("Please enter a valid phone number");
        } else {
          localStorage.setItem("FormValues", JSON.stringify(mergedObject));
          handleSubmit();
        }
      }
    }
    setClicked(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue(() => ({
      ...formValue,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (clicked) {
      const timer = setTimeout(() => {
        if (
          (currentIndex === 0 && Object.values(responses)[0] === "yes") ||
          (currentIndex === 1 && Object.values(responses)[1] === "no")
        ) {
          setCurrentIndex((prevIndex) => prevIndex + 1);
          setClicked("");
        } else {
          navigate("/form/sorry");
        }
      }, 200);
      return () => clearTimeout(timer);
    }
  }, [clicked, currentIndex, responses, navigate]);

  return (
    <>
      <div className="form-progressBar">
        <div className="number" style={defaultStyle}>
          <img src={rightImg} alt="arrow" />
        </div>
        <img className="arrow" src={arrowImg} alt="arrow" />
        {numbers?.map((num, index) => (
          <React.Fragment key={index}>
            <div
              className="number"
              style={currentIndex >= index ? defaultStyle : {}}
            >
              {currentIndex <= index ? num : <img src={rightImg} alt="" />}
            </div>
            {index < numbers.length - 1 && (
              <img className="arrow" src={arrowImg} alt="arrow" />
            )}
          </React.Fragment>
        ))}
      </div>
      <div className="form-que1" key={dataID}>
        <img src={PurpleRing} alt="" className="ring" />
        <h3>{question}</h3>
        <p>
          {description1} {description2 && <br />}
          <span>{description2}</span>
        </p>
        {currentIndex < 2 && (
          <div className="ansBtn">
            <button
              className="btn"
              value="yes"
              style={getButtonStyle("yes")}
              onClick={(e) => {
                handleClick("yes");
                setClicked("yes");
              }}
            >
              Yes
            </button>
            <button
              value="no"
              className="btn"
              style={getButtonStyle("no")}
              onClick={(e) => {
                handleClick("no");
                setClicked("no");
              }}
            >
              No
            </button>
          </div>
        )}
        {currentIndex === 2 && (
          <div className="fullName">
            <div className="column fullName-col">
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                placeholder="Alex"
                name="firstName"
                id="firstName"
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </div>
            <div className="column fullName-col">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                placeholder="John Smith"
                name="lastName"
                id="lastName"
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </div>
          </div>
        )}
        {currentIndex === 3 && (
          <div className="email">
            <div className="column">
              <label htmlFor="email">Enter your email address</label>
              <input
                type="text"
                name="email"
                placeholder="Email..."
                id="email"
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </div>
          </div>
        )}
        {currentIndex === 4 && (
          <div className="phoneNumber">
            <div className="column">
              <label htmlFor="phone-input">Telephone Number *</label>
              <input
                id="phone-input"
                type="tel"
                name="phone"
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </div>
          </div>
        )}
        {currentIndex !== 0 && currentIndex !== 1 && (
          <div className="continueBtn">
            <button type="button" onClick={handleContinue}>
              {currentIndex === FormData.length - 1 ? "Submit" : "Continue"}
              <span>
                <LazyLoadImage className="arrow" src={rightArrow} alt="arrow" />
              </span>
            </button>
          </div>
        )}
        <div className="lockInfo">
          {currentIndex !== FormData.length - 1 ? (
        <div>
            <img src={lockImg} alt="lock" />
            <p>Your Information is safe & secure</p>
        </div>
          ) : (
            <div>
            <input
                type="checkbox"
                checked={!checkBox}
                id="last-check"
                onChange={handleCheckBox}
              />
              <p>
               <label htmlFor="last-check">
               By clicking 'submit', I consent for the Claim Solutions Scotland
                to process the submitted personal data, including health data,
                to contact me in relation to a potential claim{" "}
                <a
                  href="https://www.claim-solutions-scotland.co.uk/privacy-policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  privacy policy.
                </a>
               </label>
              </p>
            </div>
          )}
        </div>
      </div>
      {revID !== undefined && (
        <div className="form-rev">
          <div className="container">
            <div className="review-image">
              <LazyLoadImage src={userImage} alt="user" />
            </div>
            <div className="review-details">
              {renderStarImages(5)}
              <p dangerouslySetInnerHTML={{ __html: desc }}></p>
              <span className="user">
                <span className="userName">{userName}</span>
                <div className="verified">
                  <LazyLoadImage className="check" src={checkImg} alt="check" />
                  <span>Verified customer</span>
                </div>
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Que1;
