import React, { useEffect, useState } from "react";
import "./Banner.scss";
import BgRing from "../../assests/images/banner-bg-ring.svg";
import FiveStar from "../../assests/images/five-star.svg";
import TrustPilot from "../../assests/images/trustpilot.svg";
import BlackCheckMark from "../../assests/images/black-checkmark.svg";
import PadLock from "../../assests/images/padlock.svg";
import { useNavigate } from "react-router";
const Banner = () => {
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState(null);

  const handleButtonClick = (text) => {
    setActiveButton(text);
  };
  useEffect(() => {
    if (activeButton !== null) {
      const timer = setTimeout(() => {
        navigate("/form");
        localStorage.setItem("selectedButtonText", activeButton);
      }, 200);
      return () => clearTimeout(timer);
    }
  }, [activeButton, navigate]);
  return (
    <div className="b_body">
      <section className="banner container1286">
        <figure className="banner__ring">
          <img src={BgRing} alt="" srcSet="" />
        </figure>
        <div className="wrapper ">
          <div className="banner__details">
            <div className="banner__details-rating">
              <span>
                <strong>Excellent</strong>
              </span>
              <span>
                <img src={FiveStar} alt="" />
              </span>
              <span>
                <strong>1,135+</strong> Reviews On
              </span>
              <span>
                <img src={TrustPilot} alt="" />
              </span>
            </div>
            <div className="banner__details-header">
              <h1>
                <span className="banner-different">See If You Qualify</span> For
                a <span className="break">Free Personal Injury Claim</span>
                <span className="break">
                  {" "}
                  Assessment{" "}
                  <span className="n2 banner-different ">In 30 Seconds</span>
                </span>
              </h1>
            </div>
            <div className="banner__details-list">
              <li>
                <figure>
                  <img src={BlackCheckMark} alt="" />
                </figure>
                <div className="listPoints">
                  Minor injuries, both{" "}
                  <strong>physical and psychological</strong>, may qualify.
                </div>
              </li>
              <li>
                <figure>
                  <img src={BlackCheckMark} alt="" />
                </figure>
                <div>
                  Complete confidentiality{" "}
                  <strong>—your job is never at risk.</strong>
                </div>
              </li>
              <li>
                <figure>
                  <img src={BlackCheckMark} alt="" />
                </figure>
                <div>
                  <strong>Pay nothing&nbsp;</strong> for our service unless we
                  win your case.
                </div>
              </li>
            </div>
            <div className="banner__details-review desk-show">
              
              <div className="review-details">
                <p>
                Claim Solutions Scotland is a claim management company and receive payment from our partnered law firms. If your free claim assessment is successful, you will be connected to a specialist law firm.
                </p>
               
              </div>
            </div>
          </div>
          <div className="banner__card">
            <h2>How were you injured?</h2>
            <p>
              This includes any minor accident that resulted in a physical
              injury
            </p>
            <div className="banner__card-buttons">
              {[
                "Accident at work",
                "Accident in a public place",
                "Accident on a private place",
                "Road Traffic Accident",
              ].map((text, index) => (
                <div className="button" key={index}>
                  <button
                    onClick={() => handleButtonClick(text)}
                    className={activeButton === text ? "active" : ""}
                  >
                    {text}
                  </button>
                </div>
              ))}
            </div>
            <p>
              <span>
                <img
                  src={PadLock}
                  alt=""
                  width={12}
                  height={14}
                  className="pad-lock"
                />
              </span>
              <span className="safe_secure">
                Your Information is safe & secure
              </span>
            </p>
          </div>
         
        </div>
        <div className="banner__description">
          <h3>Disclaimer</h3>
          Claim Solutions Scotland is a claim management company and receive payment from our partnered law firms. If your free claim assessment is successful, you will be connected to a specialist law firm.

        Claim Solutions Scotland Ltd is authorised and regulated by the Financial Conduct Authority in respect of regulated claims management activities, registration number: 837720
          {/* *National Injury Claimline Ltd is a claims management company that
          receives payments from our partnered law firms for our service. If you
          qualify for a free claim assessment and that assessment is successful,
          you will be connected to an expert law firm to assist with your
          potential claim. Our service is completely free. */}
        </div>
      </section>
    </div>
  );
};
export default Banner;
