import img1 from "../assests/images/que1-user.png";
import img2 from "../assests/images/compe-user.jpg";
import img3 from "../assests/images/que3-user.png";
import img4 from "../assests/images/que4-user.png";
import img5 from "../assests/images/que5-user.png";
import img6 from "../assests/images/que6-user.png";
import spec1 from "../assests/images/specification-1.png";
import spec2 from "../assests/images/specification-2.png";
import spec3 from "../assests/images/specification-3.png";

const FormRevData = [
  {
    id: 1,
    userImage: img1,
    desc: "“Having never made an injury claim in the past, I was impressed with the friendly, understanding and professional manner in which I was subject too.<span> All matters were clearly explained and all my queries were addressed.</span> Appears to be an excellent service. <span> I am happy I chose them.”</span>",
    userName: "Jonathan Paul Pickstock",
  },
  {
    id: 2,
    userImage: img2,
    desc: "<span>“Luke was really good</span> and patient in helping me answer all the questions correctly. Thank you so much”",
    userName: "venere valguarnera",
  },
  {
    id: 3,
    userImage: img3,
    desc: "“Phillip was very professional and knowledgeable about everything. <span>Everything explained well, precise and clear as to the next steps.”</span> ",
    userName: "Carl Taylor",
  },
  {
    id: 4,
    userImage: img4,
    desc: "<span>“The solicitor made me feel comfortable</span> even though i pronounced my words wrong as English is not my first language.<span> He was very helpful.</span> He was understanding and had empathy for my situation.” ",
    userName: "Sonia berry",
  },
  {
    id: 5,
    userImage: img5,
    desc: "“I had a very pleasant 1st hand assessment by Yui ,a very friendly, polite and professional person. The whole conversation was clear and jargon free.<span> Very pleased with the professionalism of Yui . Thank you very much 👍🏻”</span>",
    userName: "alamgir ahmed",
  },
  {
    id: 6,
    userImage: img6,
    desc: "<span>I highly recommend</span> and rate this company for it's polite, professional and fast turnaround time in responding to my claim.",
    userName: "BRITISH GEM",
  },
];
const specificationsData = [
  {
    id: 1,
    image: spec1,
    title: "Speak to us",
    desc: " We'll confirm your details, explain how we can help and what to expect.",
  },
  {
    id: 2,
    image: spec2,
    title: "We pass you through to one of our panel of solicitors",
    desc: "If your consultation is successful, we'll connect you to an expert law firm to assist with your claim.",
  },
  {
    id: 3,
    image: spec3,
    title: "We’ll review your claim",
    desc: "We will conduct a free consultation to identify the facts surrounding your case and see if it is something that a law firm could help with.",
  },
];
export { FormRevData, specificationsData };
