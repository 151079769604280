import React, { Fragment, useEffect, useState } from "react";
import clock from "../../assests/images/clock.png";
import { specificationsData } from "../../utils/FormData";
import SuccessRing from "../../assests/images/success-ring.svg";
import "./success.scss";
import FiveStar from "../../assests/images/five-star.svg";
import TrustPilot from "../../assests/images/trustpilot.svg";
const Success = () => {
  const [timeLeft, setTimeLeft] = useState(4 * 60 + 36);
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  useEffect(() => {
    if (timeLeft <= 0) return;
    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);
  return (
    <Fragment>
      <div className="success">
        <div className="success__info">
          <div className="container">
            {/* <div className="form-rating">
              Excellent <span>{renderStarImages(5)}</span>
              1,135+ <span>Reviews On</span>
              <span>
                <LazyLoadImage className="star" src={starSvg} alt="alt" />
              </span>{" "}
              TrustPilot
            </div> */}
            <div className="banner__details-rating">
              <span>
                <strong>Excellent</strong>
              </span>
              <span>
                <img src={FiveStar} alt="" />
              </span>
              <span>
                <strong>1,135+</strong> Reviews On
              </span>
              <span>
                <img src={TrustPilot} alt="" />
              </span>
            </div>
            <h2 className="title">
              <span className="sp1">
                We've successfully received your request,{" "}
              </span>
              & Our team will reach out
              <span className="sp2"> within the next 5 minutes.</span>
            </h2>
            <p className="note">*Please make sure to have your phone nearby*</p>
          </div>
        </div>
        <div className="success__timer">
          <div className="container">
            <img className="clock" src={clock} alt="clock" />
            <div className="contown">
              <div className="time">
                <p className="digit">{String(minutes).padStart(2, "0")}</p>
                <p className="unit">Min</p>
              </div>
              <p className="colon"> : </p>
              <div className="time">
                <p className="digit">{String(seconds).padStart(2, "0")}</p>
                <p className="unit">Sec</p>
              </div>
            </div>
            <h4 className="thanks">thank you</h4>
            <p className="call">
              Our Personal Injury Experts will call you shortly from:{" "}
              <span>
                <a href="tel:+01413198249">0141 319 8249.</a>
              </span>
            </p>
          </div>
          <figure className="success-ring">
            <img src={SuccessRing} alt="" />
          </figure>
        </div>
        <div className="success__specifications">
          <div className="container">
            {specificationsData?.map((data) => {
              return (
                <div className="type" key={data?.id}>
                  <img src={data?.image} alt="icon" />
                  <h5>{data?.title}</h5>
                  <p dangerouslySetInnerHTML={{ __html: data?.desc }}></p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Success;
