import React, { Fragment } from "react";
import "./compensation.scss";
import FiveStar from "../../assests/images/five-star.svg";
import TrustPilot from "../../assests/images/trustpilot-white.svg";

import { LazyLoadImage } from "react-lazy-load-image-component";
import Review from "../ReviewSection/Review";
import userImg from "../../assests/images/compe-user.jpg";
import ClaimButton from "../ClaimButton";
import comRight from "../../assests/images/compensation-right.png";
import comMB from "../../assests/images/compensation-mb.png";

const Compensation = () => {
  return (
    <Fragment>
      <div className="compensation">
        <div className="container1500">
          <div className="compensation-left">
            <div className="banner__details-rating compensationRating">
              <span>
                <strong>Excellent</strong>
              </span>
              <span>
                <img src={FiveStar} alt="" srcSet="" />
              </span>
              <span>
                <strong>1,135+</strong> Reviews On
              </span>
              <span>
                <img src={TrustPilot} alt="" />
              </span>
            </div>
            <p className="subTitle">What are you waiting for?</p>
            <h2>
              <span>Claim Your&nbsp;</span>Free Claim Assessment Today!
            </h2>
            <p className="desc">
              Don’t wait to get what you deserve. Start your compensation
              process now and take the first step toward securing the financial
              support you need. Your claim matters—act today!
            </p>
            <ClaimButton />
            <Review
              userImage={userImg}
              star={5}
              strong="Luke was really good"
              desc="and patient in helping me answer all the questions correctly. Thank you so much"
              userName="Suzanne"
            />
          </div>
          <div className="compensation-right deskView">
            <LazyLoadImage
              className="comRightImg"
              src={comRight}
              alt="compensation"
            />
          </div>
          <div className="compensation-right mbView">
            <LazyLoadImage
              className="comRightImg"
              src={comMB}
              alt="compensation"
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Compensation;
