import Star from "../assests/images/whiteStar.svg";
import ShieldPlus from "../assests/images/safety-shield.svg";
import Info from "../assests/images/info.svg";

const StripData = [

  {
    id: 1,
    image: Star,
    text: "100+ Happy Customers",
  },
  {
    id: 2,
    image: ShieldPlus,
    text: "100% Safe and Secure",
  },
  {
    id: 3,
    image: Info,
    text: "Free Information",
  },
];

export default StripData;
