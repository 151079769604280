import React, { Fragment } from 'react'
import "./speak.scss";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ClaimButton from '../ClaimButton';
import comRight from "../../assests/images/about.png";
import chatImg from "../../assests/images/about-chat.png";
import aboutMb from "../../assests/images/about-mb.png";
const Speak = () => {
    return (
        <Fragment>
            <div className='speak'>
                <div className='container1500'>
                    <div className='speak-left'>
                        <LazyLoadImage
                            className='chatDescView'
                            src={chatImg}
                            alt='chat'
                        />
                        <h2>
                            Speak To Us Now
                        </h2>
                        <p className='desc'>
                            Fill out the form and then you will get a call to discuss your potential claim
                        </p>
                        <p className='desc'>
                            FREEPHONE UK Based Experts | Open 7 Days a Week 8.00am - 7.00pm
                        </p>
                        <ClaimButton />
                    </div>
                    <div className='speak-right deskView'>
                        <LazyLoadImage
                        className='comRightImg'
                        src={comRight}
                        alt='speak'
                        />
                    </div>
                    <div className='speak-right mbView'>
                        <LazyLoadImage
                        className='comRightImg'
                        src={aboutMb}
                        alt='speak'
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Speak
