import React, { Fragment } from "react";
import "./disclaimer.scss";

const Disclaimer = () => {
  return (
    <Fragment>
      <div className="disclaimer">
        <div className="container1500">
          <h2>Disclaimer :</h2>
          <div className="desc">
            <p>
              We are a claim management company and receive payment from our partnered law firms. If your free claim assessment is successful, you will be connected to a specialist law firm. Typically, customers pay 20% inclusive of VAT, of the compensation amount that is recovered by our third-party law firms, although this is subject to your individual circumstances and the actual fee may be more or less than this. Termination fees may apply if you do not keep to the terms of the agreement. Claim Solutions Scotland is authorized and regulated by the Financial Conduct Authority in respect of regulated claim management activities, registration number: 837720
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Disclaimer;
