import React, { Fragment, useState, useEffect } from "react";
import announcementBarImg from "../../../src/assests/images/announcementBar.png";
import Marquee from "react-fast-marquee";

const AnnpuncementBar = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 991);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Fragment>
      <div className="announcementBar ">
        <div className="announcementBar-info container1500">
          <img src={announcementBarImg} alt="img" />

          {isMobile ? (
            <Marquee speed={30} delay={2}>
              <p>
              Claim Solutions Scotland is a claim management company and receive payment from our partnered law firms. If your free claim assessment is successful, you will be connected to a specialist law firm. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </p>
            </Marquee>
          ) : (
            <p>
            Claim Solutions Scotland is a claim management company and receive payment from our partnered law firms. If your free claim assessment is successful, you will be connected to a specialist law firm. 
            </p>
          )}
        </div>
      </div>
    </Fragment>
  );
};
export default AnnpuncementBar;
